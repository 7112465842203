.analytics-card{
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
}

bs-datepicker-container, bs-daterangepicker-container {
    z-index: 999999;
}

.analytics-card h1.title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #07366A;
}

.analytics-card h5.sub-title{
    font-size: 11px;
    line-height: 16px;
    color: #A0A4A8;
}


.full-view-container{
    padding: 25px;
    max-width: 90%;
    margin: 25px auto;
    background-color: #ffffff;
    border-radius: 12px;
}

.analytics-card{
    min-height: 500px;
}

.InboxChartEmpty{
    text-align: center;
    margin: 40px 0;
}

.funnels-add .ng-select.ng-select-single .ng-select-container {
    height: 40px;
    border: 1px solid #EBECF0 !important;
}

app-user-behavior-chart svg rect.clonedNode {
    fill: #004A9B;
}

app-user-behavior-chart svg rect, app-user-behavior-chart svg text, .onlyWelcomeMsg path{
    fill-opacity: 0;
    visibility: hidden;
}

app-user-behavior-chart svg rect.clonedNode, app-user-behavior-chart svg text.clonedNode {
    fill-opacity: 1;
    visibility: visible;
}

.behavior-tooltip {
    width: 270px;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(208, 208, 208, 0.25);
    background-color: #000A3F;
    color: #ffffff;
    padding: 8px;
    font-size: 14px;
}

body[dir="rtl"] .funnel-chart div {
    direction: rtl !important;
}

.funnelModal {
    width: 650px !important;
    max-width: 800px !important;
}

.delete-Funnel-Modal {
    width: 538px !important;
}
