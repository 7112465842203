@import "~bootstrap/dist/css/bootstrap.css";
@import "~material-icons/iconfont/material-icons.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import url("https://fonts.googleapis.com/css?family=Cairo:400,600,700");
@import "~@ng-select/ng-select/themes/default.theme.css";
@font-face {
  font-family: SpaceGrotesk;
  src: url(assets/fonts/SpaceGrotesk-Regular.woff2);
  font-weight: 200 !important;
}

@font-face {
  font-family: SpaceGrotesk;
  src: url(assets/fonts/SpaceGrotesk-Bold.woff2);
  font-weight: bold;
}

html {
  font-size: 15px;
}

body {
  background-color: rgba(0, 0, 0, 0.05) !important;
  font-family: SpaceGrotesk, "Cairo", sans-serif;
  color: #1b2733;
  max-width: 100%;
  overflow: hidden;
  font-size: 0.95rem;
}

ng-component[_nghost-c1] {
  height: 100% !important;
}

button:focus {
  outline: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  padding-right: 5px;
  cursor: default;
  scrollbar-width: 7px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: #fff !important;
  border-color: #ced4da !important;
}

/* user attr span styles */

.userAttributeText {
  color: #2956ef;
  background: #f0f0f0;
  cursor: pointer;
  border-radius: 50px;
  padding: 0;
  direction: ltr !important;
}

.userAttributeText.invalid {
  color: #d32f2f;
}

.userAttributeText:hover {
  background: #ddd;
}

.userAttributeText--focused,
.userAttributeText--focused:hover,
.userAttributeText::selection {
  background: #2956ef;
  color: #fff;
  border-radius: 4px;
}

.userAttributeText--focused.invalid,
.userAttributeText--focused.invalid:hover {
  background: #d32f2f;
  color: #fff;
  border-radius: 4px;
}

.mirror span.attribute-line {
  display: inline-block;
  width: 100%;
  unicode-bidi: plaintext;
}

.inputContainer.oneLineInput--blured .mirror span.attribute-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* End attr span styles */

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  cursor: not-allowed !important;
}

.ng-select .ng-select-container .ng-value-container {
  flex-wrap: nowrap !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  width: 90% !important;
}

body[dir="rtl"] .ng-select .ng-select-container {
  direction: rtl;
}

.ng-select .ng-select-container,
.ng-select .ng-select-container .ng-value-container .ng-input > input {
  cursor: pointer !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background: #fcf5f5;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  overflow: visible !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 150px !important;
}

.ng-dropdown-panel.ng-select-bottom {
  margin-top: 3px;
}

.ng-dropdown-panel {
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
  border: none;
  z-index: 9999999999999999999999 !important;
}

.ng-dropdown-panel.users-filter {
  min-width: fit-content !important;
  z-index: 99999;
  filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.2));
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border-radius: 4px !important;
}

/* Customize the label (the container) */

label {
  cursor: pointer;
}

.option-conainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 0.5rem;
  cursor: pointer;
  color: #6a6a6a;
}

/* Hide the browser's default radio button */

.option-conainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */

.option-conainer .checkmark {
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 2px solid #6a6a6a;
  margin-inline-end: 0.3rem;
  border-radius: 50%;
  position: relative;
}

/* On mouse-over, add a grey background color */

.option-conainer:hover input ~ .checkmark {
  background-color: #fafafa;
}

/* When the radio button is checked, add a blue background */

.option-conainer input:checked ~ .checkmark {
  background-color: #fff;
  border-color: #2956ef;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.option-conainer .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.option-conainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */

.option-conainer .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #2956ef;
}

.helpIcon .cls-1 {
  fill: #f6ba14;
}

.helpIcon .cls-2 {
  fill: #fff;
}

.pointer {
  cursor: pointer;
}

.checkbox-container label span.checkmark {
  border-radius: 4px;
  border-color: #2956ef;
  margin-inline-end: 0.5rem;
  flex: 0 0 auto;
}

.checkbox-container label {
  align-items: flex-start;
}

.checkbox-container label span {
  color: #6a6a6a !important;
  font-size: 1rem;
  margin: 0;
  line-height: 1.05;
}

/* checkbox mark  checkmark/indicator*/

.checkbox-container .checkmark:after {
  background: transparent;
  border-radius: 0;
  width: 7.5px;
  height: 15px;
  border: solid #2956ef;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg) translate(-100%, -50%);
}

/* Missing angular tree component css */

.tree-children.tree-children-no-padding {
  padding-left: 0;
}

.tree-children {
  padding-left: 20px;
  overflow: hidden;
}

.node-drop-slot {
  display: block;
  height: 2px;
}

.node-drop-slot.is-dragging-over {
  background: #ddffee;
  height: 20px;
  border: none;
}

.toggle-children-wrapper-expanded .toggle-children {
  transform: rotate(90deg);
}

.toggle-children-wrapper-collapsed .toggle-children {
  transform: rotate(0);
}

.toggle-children-wrapper {
  position: relative;
}

.toggle-children {
  display: none;
}

.toggle-children-placeholder {
  display: inline-block;
  height: 10px;
  width: 10px;
  position: relative;
  top: 1px;
  padding-right: 3px;
}

.node-content-wrapper {
  display: inline-block;
  padding: 2px 5px;
  transition: background-color 0.15s, box-shadow 0.15s;
}

.node-wrapper {
  display: flex;
  align-items: flex-start;
}

.node-content-wrapper-active,
.node-content-wrapper.node-content-wrapper-active:hover,
.node-content-wrapper-active.node-content-wrapper-focused {
  background: #beebff;
}

.node-content-wrapper-focused {
  background: #e7f4f9;
}

.node-content-wrapper:hover {
  background: #f7fbff;
}

.node-content-wrapper-active,
.node-content-wrapper-focused,
.node-content-wrapper:hover {
  box-shadow: inset 0 0 1px #999;
}

.node-content-wrapper.is-dragging-over {
  background: #fff;
  box-shadow: none;
}

.node-content-wrapper.is-dragging-over-disabled {
  opacity: 0.5;
}

tree-viewport {
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  overflow: auto;
  display: block;
}

.empty-tree-drop-slot .node-drop-slot {
  height: 20px;
  min-width: 100px;
}

.angular-tree-component {
  width: 100%;
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE/Edge */
  user-select: none;
  /* non-prefixed version, currently not supported by any browser */
}

tree-root .angular-tree-component-rtl {
  direction: rtl;
}

tree-root .angular-tree-component-rtl .toggle-children-wrapper-collapsed .toggle-children {
  transform: rotate(180deg) !important;
}

tree-root .angular-tree-component-rtl .tree-children {
  padding-right: 20px;
  padding-left: 0;
}

tree-node-checkbox {
  padding: 1px;
}

/* End Missing angular tree component css */

/*-----------------------------------Layout-------------------------------*/

.side-scrollbar {
  height: 100%;
}

.main-scrollbar {
  height: 100%;
}

aside {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
}

body[dir="rtl"] aside {
  border: 0;
}

body[dir="rtl"] ul {
  direction: rtl;
}

body[dir="rtl"] .float-left {
  float: right !important;
}

body[dir="rtl"] .float-right {
  float: left !important;
}

/*********************** Reusable Styles***********************/

.d-flex.gap {
  gap: 1rem;
}

.overflowY-auto {
  max-height: 95%;
  overflow-x: hidden;
  overflow-y: auto;
}

.app-border {
  border-color: #bce0fd !important;
}

.secondry-border {
  border-color: #fad6f6 !important;
}

.text-danger {
  color: #d32f2f !important;
}

.card {
  box-shadow: 0 1px 4px 0 #00000014;
  background-color: #ffffff;
}

.card .card-header,
.card .card-footer {
  padding: 0;
  background-color: transparent;
  border-bottom: 0;
  border-top: 0;
}

.card .card-title {
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin-bottom: 0.25rem;
}

.card.card-bot .card-title {
  color: #1b2733;
}

.card .card-text {
  font-size: 0.8125rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
}

.card-bot .card-text {
  color: #95aab4;
}

.card .card-image-wrapper {
  width: 100%;
  height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 0.25rem;
}

.clickable-card {
  cursor: pointer;
}

.icon {
  display: block;
}

.icon svg {
  vertical-align: sub;
  fill: #8aa1ac;
}

.breadcrumb {
  background-color: transparent !important;
}

body[dir="rtl"] .breadcrumb-item + .breadcrumb-item::before {
  float: right;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/";
}

.mx-100 {
  max-width: 100% !important;
}

.app-dropdown-list {
  text-align: left;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #a9cef8;
  background: #fff;
  margin-top: 0.1rem;
  overflow: hidden;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 7;
}

.app-dropdown-list ul {
  padding: 0;
  margin: 0;
  max-height: 100px;
  overflow-y: scroll;
}

.app-dropdown-list ul li {
  list-style: none;
  padding: 0.6rem 0.5rem;
  font-weight: 500;
}

.app-dropdown-list ul li:hover {
  background: #eee;
  cursor: pointer;
}

.message-container {
  padding: 0.7rem;
  border: 1px solid #fad6f6;
  border-radius: 16px;
}

.rotate-180 {
  transform: rotate(180deg);
}

.plain-text {
  unicode-bidi: plaintext;
}

.line-through {
  text-decoration: line-through !important;
}

/*-------------------------------Playground---------------------------------------*/

.folder {
  margin-bottom: 2.5rem;
}

.folder-name,
.flow-name {
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  padding: 0.2rem 0.1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
  white-space: nowrap;
}

.flowName {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 19rem;
  white-space: nowrap;
}

.folder-name {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.33;
  unicode-bidi: plaintext;
}

.flow {
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border: solid 1px #fad6f6;
  display: inline-block;
  padding: 0.35rem 0.3rem;
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  color: #6d757d;
}

.flow:hover {
  color: #000;
}

.flow-name {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.29;
  margin: 0;
}

.flow-editor {
  width: 26rem;
  padding: 1rem;
  border-radius: 6px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  /* border: solid 1px #fad6f6; */
}

/*-------------------------------Buttons------------------------------------------*/

button[disabled] {
  cursor: unset !important;
}

.button-icon {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  outline: 0;
  max-height: fit-content;
}

.button-editor:hover {
  color: #000;
}

.createQuickReplyButton button:hover {
  color: #000;
}

.button-editor span {
  font-size: 0.77rem;
  font-weight: 600;
}

.addBtnBtn button {
  border: none;
  width: 100%;
  background-color: transparent;
  text-align: center !important;
  padding: 0.75rem;
  font-size: 14px;
  color: #95aab4;
  cursor: pointer;
  outline: none;
  transition: 0.3s;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top: 1px solid #fad6f6;
}

.addBtnBtn button:hover {
  color: #333;
}

.addBtnBtn button.disable {
  cursor: not-allowed;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
}

.userAttrInput-container {
  border-radius: 4px;
  border: solid 1px #a9cef8;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  background: #fff;
  cursor: text;
}

.createMessageButton.broadcastMessages {
  border-bottom: none !important;
}

.messageTypeMenu.broadcastMessages .button-editor:nth-child(5) {
  border-bottom: none !important;
}

/*--------------------------------Font Sizes----------------------------------------*/

.font-10 {
  font-size: 0.625rem;
}

.font-11 {
  font-size: 0.6875rem;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 0.875rem;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 1.125rem;
}

.font-20 {
  font-size: 1.25rem;
}

.font-22 {
  font-size: 1.375rem;
}

.font-24 {
  font-size: 1.5rem;
}

/*-----------------------Overriding Bootstrap Styles--------------------------------*/

/*-----------------------------------To Do------------------------------------------*/

/*Convert the project to scss and theming the bootsrap instead of overriding its styles*/

.btn {
  padding: 0.375rem 0.5rem;
}

.btn-primary,
.bg-primary {
  background-color: #2956ef !important;
  border-color: #2956ef;
  color: #fff;
}

.btn-primary:hover {
  background-color: #5076f5;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.btn-outline-primary:hover {
  background-color: #2956ef;
  border-color: #2956ef;
}

.text-primary {
  color: #2956ef !important;
}

.text-secondary {
  color: #95aab4 !important;
}

.text-danger {
  color: #e74c3c !important;
}

.text-warning {
  color: #f7bb14 !important;
}

.text-success {
  color: #27ae60 !important;
}

.text-gray {
  color: #5e6c84;
  font-size: 14px;
}

.list-group {
  border-radius: 4px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}

.list-group-item {
  border-color: #d2edfa;
}

.form-control:focus {
  box-shadow: none;
}

body[dir="rtl"] .form-check {
  direction: rtl !important;
}

.form-check-label {
  margin-inline-start: 1rem;
}

.node-content-wrapper {
  width: 100% !important;
  border-radius: 5px;
  margin-top: 5px;
}

.app-input {
  padding: 0.2rem;
  border: 1px solid #d5dce6;
  border-radius: 4px;
  font-size: 1rem;
  display: block;
  width: 100%;
}

.app-input:focus {
  border-color: #bce0fd;
}

.large-input {
  padding: 0.6rem;
  font-size: 1.4rem;
}

/* .node-content-wrapper:active{
  cursor: -webkit-grabbing; cursor: grabbing;
  } */

/* .node-content-wrapper:hover{
  cursor: -webkit-grab; cursor: grab;
} */

/* To simulate one line input */

.oneLineContentEditable {
  white-space: nowrap !important;
  overflow: hidden !important;
}

.oneLineContentEditable br {
  display: none !important;
}

.oneLineContentEditable * {
  display: inline !important;
  white-space: nowrap !important;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

/* End */

div.node-wrapper {
  position: relative;
  padding: 0 10px !important;
}

tree-node-expander {
  position: absolute;
  top: 16px;
  left: 5px;
  z-index: 8;
}

.node-content-wrapper-active,
.node-content-wrapper-active.node-content-wrapper-focused,
.node-content-wrapper.node-content-wrapper-active:hover {
  background: transparent;
  box-shadow: none;
}

span.toggle-children-wrapper::before {
  border-style: solid;
  border-width: 0.1em 0.1em 0 0;
  content: "";
  display: inline-block;
  height: 12px;
  width: 12px;
  position: absolute;
  top: 8px;
  left: 5px;
  z-index: 17;
  border-color: #2956ef;
  vertical-align: top;
  transform: translateY(50%) rotate(45deg);
  cursor: pointer;
  transition: 0.3s;
}

body[dir="rtl"] span.toggle-children-wrapper::before {
  transform: translateY(-150%) rotate(45deg);
}

span.toggle-children-wrapper-expanded::before {
  transform: translateY(50%) rotate(135deg);
}

body[dir="rtl"] span.toggle-children-wrapper-expanded::before {
  transform: translateY(-150%) rotate(-45deg);
}

body[dir="rtl"] .header-content {
  direction: rtl;
}

.slick-list {
  direction: ltr;
}

.node-drop-slot {
  height: 10px;
}

div.tree-children {
  margin: 10px;
  border: 1px solid #fad6f6;
  border-radius: 10px;
}

div.tree-children .menuItemContainer:first-of-type {
  border-top: 0;
}

div.tree-children .menuItemContainer input.menuTitle {
  padding-top: 7px;
  padding-bottom: 7px;
}

.node-content-wrapper-active,
.node-content-wrapper-active.node-content-wrapper-focused {
  background: #fff;
}

tree-viewport {
  overflow: visible;
}

.toggle-children {
  background: none;
}

.tree-children {
  padding-left: 0 !important;
  overflow: visible;
}

.node-content-wrapper-active,
.node-content-wrapper.node-content-wrapper-active:hover,
.node-content-wrapper-active.node-content-wrapper-focused {
  background: #eee;
}

.node-content-wrapper-active,
.node-content-wrapper.node-content-wrapper-active:hover,
.node-content-wrapper-active.node-content-wrapper-focused {
  background: #eee;
}

.node-content-wrapper-active,
.node-content-wrapper-focused,
.node-content-wrapper:hover {
  box-shadow: none;
}

.counter {
  position: absolute;
  padding: 0 5px;
  background-color: #fff;
  border-radius: 30px;
  min-width: 25px;
  height: 18px;
  line-height: 15px;
  text-align: center;
  box-shadow: 0px 0px 1px #eee;
  border: solid 1px #fad6f6;
  font-size: 10px;
  font-weight: 600;
  right: 5px;
  z-index: 2;
}

.inputCounterContainer {
  position: relative;
}

.counter.Invalid {
  color: #d32f2f;
  border-color: #d32f2f;
}

.Invalid {
  border-color: #d32f2f !important;
}

.modal-container {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 80vh;
}

.modal-dialog {
  display: flex;
  width: 100%;
  height: 90%;
  justify-content: center;
  align-items: center;
  margin: 2rem auto 0 !important;
}

.modal-header {
  padding: 0;
  padding-bottom: 10px;
  border: 0;
  margin-bottom: 25px;
  border-bottom: 1px solid #e9ecef;
}

.modal-body {
  padding: 1rem;
  color: #000;
}

.modal-content {
  padding: 1rem;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
}

.modalActions {
  padding-top: 10px;
  margin-top: 25px;
  display: flex;
  justify-content: space-around !important;
}

.modalActions button {
  min-width: 150px;
  padding: 8px 0;
}

.modalActions button:first-of-type {
  margin-left: 10px;
}

.modal-header .modal-title {
  font-size: 22px;
  font-weight: 600;
}

.modal-header .close {
  padding: 0;
  margin: 0;
  font-size: 38px !important;
}

.modal-header .close svg {
  width: 15px;
  fill: #8aa1ac;
}

.modal-content {
  border: none;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
}

.modal-dialog.xLarge-modal {
  max-height: 93vh;
  margin: 2rem auto 0 !important;
}

.alertContainer .content a {
  text-decoration: underline;
  font-weight: 600;
  color: #fff;
}

.alertContainer.warning .content a {
  color: #101010;
}

button.dimmedButton,
button.dimmedButton:hover {
  opacity: 0.5 !important;
  cursor: default !important;
  color: #6d757d !important;
}

.mat-select-panel {
  border-radius: 5px;
}

.mat-select,
.mat-option,
.mat-form-field,
.mat-calendar,
.mat-button,
.mat-tab-label {
  font-family: SpaceGrotesk, "Cairo", sans-serif;
}

.mat-button-base.mat-ripple-disabled {
  background-clip: initial;
}

timepicker a.btn.btn-link {
  opacity: 0;
  transition: 0.3s;
}

timepicker:hover a.btn.btn-link {
  opacity: 1;
}

timepicker button.btn {
  margin-left: -10px;
  background-color: #fff;
}

timepicker input.form-control,
timepicker button.btn {
  color: #2956ef;
  font-weight: 600;
  font-size: 1.2rem;
  padding: 5px;
}

timepicker input.form-control {
  padding: 0;
}

timepicker button.btn:hover {
  background-color: #eee;
}

timepicker input.form-control {
  border-width: 0;
}

timepicker:hover input.form-control,
timepicker input.form-control:focus {
  border-width: 1px;
}

timepicker table {
  color: #2956ef;
}

.btn-link:focus {
  text-decoration: none !important;
}

.btn.btn-link:focus {
  box-shadow: none;
  border: none;
  outline: none;
  text-decoration: none;
}

.bs-timepicker-field {
  width: 40px;
}

input.input-large {
  height: 55px;
  font-size: 1.25rem;
  padding: 0.375rem 1.25rem;
}

button {
  cursor: pointer;
}

input {
  outline: none;
  unicode-bidi: plaintext;
}

input:focus {
  border-color: #2956ef;
}

.autoPlaintext,
.flow-name {
  unicode-bidi: plaintext !important;
}

.broadcastCreateTime .mat-form-field-infix {
  width: auto;
  min-width: 62px !important;
  max-width: 150px;
  padding: 5px 3px;
  border: none;
  line-height: 1.5;
}

.userAttrType .mat-form-field-infix {
  width: auto;
  min-width: 54px !important;
  max-width: 150px;
  padding: 7px 3px;
  border: none;
  line-height: 1.5;
}

.sla_time .mat-form-field-infix {
  width: auto;
  min-width: 54px !important;
  max-width: 150px;
  padding: 7px 3px;
  border: none;
  line-height: 1.5;
}

.buttonAction .mat-form-field-infix {
  min-width: 45px !important;
}

.broadcastCreateTime .repeat .mat-form-field-infix,
.userAttrType .repeat .mat-form-field-infix .sla_time .mat-form-field-infix {
  width: 100%;
  max-width: 100%;
}

.broadcastCreateTime .time .mat-form-field-infix,
.sla_time .mat-form-field-infix {
  /* min-width: 54px !important; */
  min-width: 60px !important;
  line-height: 1.5;
}

.broadcastCreateTime .time .mat-form-field,
.userAttrType .time .mat-form-field,
.sla_time .mat-form-field {
  margin-right: 5px;
}

.broadcastCreateTime .time .mat-form-field-infix,
.broadcastCreateTime .sla_time .mat-form-field-infix {
  /* min-width: 54px !important; */
  min-width: 60px !important;
  line-height: 1.5;
}

.broadcastCreateTime .mat-form-field-appearance-legacy .mat-form-field-underline,
.sla_time .mat-form-field-appearance-legacy .mat-form-field-underline,
.userAttrType .mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}

.broadcastCreateTime .mat-form-field-appearance-legacy .mat-form-field-wrapper,
.sla_time .mat-form-field-appearance-legacy .mat-form-field-wrapper,
.userAttrType .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  background-color: #fff;
  padding: 0;
  border: 1px solid #bce0fd;
  border-radius: 5px;
}

.condition-input .userAttrInput .attrList,
.condition-input .userAttrInput .attrList input {
  height: 100% !important;
}

.mat-datepicker-content {
  border-radius: 5px !important;
  border: 1px solid #a9cef8;
}

.mat-calendar-body-cell-content {
  border-radius: 5px !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: #fff;
}

.mat-calendar-body-selected {
  background-color: #b4e1fa;
  color: #000;
}

.cdk-overlay-container {
  background: rgba(0, 0, 0, 0.5);
}

.modalActions button {
  background-color: transparent;
  border: 1px solid;
  color: #9b9b9b;
  font-weight: 500;
}

.modalActions button:hover {
  color: #000;
}

.modalActions button.confirm {
  border-color: #6dbeff;
}

.modal-body {
  padding: 0px;
}

.modal-backdrop,
.modal {
  z-index: 50002;
}

.modal-upgrade {
  max-width: 500px;
  overflow: auto;
  padding: 1rem;
}

.modal-upgrade .modal-content {
  max-height: 95%;
  overflow: auto;
  padding: 0;
}

app-crm-user-attributes {
  display: grid;
}

.userAttributesModal .modal-content {
  width: 120%;
}

.webChatModal {
  min-width: 40rem !important;
}

body[dir="rtl"] .webChatModal,
body[dir="rtl"] .downgradeModal {
  direction: rtl;
}

.downgradeModal .modal-content,
.webChatModal .modal-content {
  max-height: 98%;
  overflow-y: auto;
}

.userAttributesModal {
  min-width: 43rem;
}

body[dir="rtl"] .ar-direction {
  float: right !important;
}

body[dir="rtl"] .model-header .close {
  float: left !important;
}

body[dir="rtl"] .btnsAr-dirction {
  justify-content: flex-start !important;
}

body[dir="rtl"] .modalContent {
  direction: ltr;
}

body[dir="rtl"] .switchPlan h6 {
  direction: rtl;
}

body[dir="rtl"] .filterByText-container button {
  left: 0.6rem;
  right: auto !important;
}

body[dir="rtl"] .package-type li {
  margin-right: 0.5rem !important;
}

body[dir="rtl"] .package-type li svg {
  transform: rotatey(180deg);
}

.modalActions button.cancel {
  border-color: #f9cfd5;
}

.long-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 70%;
}

.break-word {
  word-wrap: break-word;
}

/* rtl styles */

body[dir="rtl"] .messageTypeMenu-afterMsg.broadcastMessages .button-editor-dropup {
  width: 100% !important;
  direction: rtl;
}

body[dir="rtl"] .pagesContainer {
  min-width: 35rem;
}

body[dir="rtl"] .page-disconnect {
  direction: rtl;
}

body[dir="rtl"] .ar-card {
  min-width: 35rem;
}

body[dir="rtl"] .upgradeBot svg {
  margin-left: 8px !important;
  margin-right: 0px !important;
}

body[dir="rtl"] .btn-dropup {
  padding-top: 1.5rem;
}

body[dir="rtl"] .folder-dir {
  text-align: right;
}

body[dir="rtl"] .flow-name {
  direction: rtl;
}

body[dir="rtl"] .button-editor span {
  font-size: 0.66rem;
}

body[dir="rtl"] .card-ar {
  direction: rtl;
}

body[dir="rtl"] .admin-info {
  margin-inline-end: 0.9rem;
}

body[dir="rtl"] .card-ar .card-text {
  line-height: 2.31;
}

body[dir="rtl"] .card-ar .card-text {
  margin-left: 2.5rem;
  margin-right: 0 !important;
}

body[dir="rtl"] .card-ar button {
  direction: ltr;
}

body[dir="rtl"] .detailsContainer .welcomeMessage div.h1 {
  padding-left: 0 !important;
  padding-right: 28px;
}

body[dir="rtl"] .welcomeMessageWrapper textarea {
  direction: rtl;
}

body[dir="rtl"],
body[dir="rtl"] .dropdown-menu,
body[dir="rtl"] input,
body[dir="rtl"] .text-left,
body[dir="rtl"] textarea,
body[dir="rtl"] .mat-select-value,
body[dir="rtl"] .mat-option-text {
  text-align: right !important;
}

body[dir="rtl"] .defaultFlows {
  direction: rtl;
}

body[dir="rtl"] input,
body[dir="rtl"] .gallery-card-body + .gallery-card-body,
body[dir="rtl"] .main-scrollbar,
body[dir="rtl"] .listItemInputs,
body[dir="rtl"] .itemsCounter,
body[dir="rtl"] .itemButtons,
body[dir="rtl"] .autoBilling,
body[dir="rtl"] .switchPlans p span,
body[dir="rtl"] app-account-settings-plans .cardsContainer,
body[dir="rtl"] app-account-settings-plans .cardsContainer li,
body[dir="rtl"] .broadcastAlert,
body[dir="rtl"] .messageTypes,
body[dir="rtl"] .step-4 .stepBody,
body[dir="rtl"] .step-3,
body[dir="rtl"] .msgContent .textmessage,
body[dir="rtl"] .modal-body,
body[dir="rtl"] .msgContent span.requiredMessage,
body[dir="rtl"] .page-title,
body[dir="rtl"] .card.card-bot .card-title,
body[dir="rtl"] .selectTargetMenu,
body[dir="rtl"] .broadCast-time-date .date,
body[dir="rtl"] .messageTypeMenu.broadcastMessages,
body[dir="rtl"] .manager-container,
body[dir="rtl"] .analyticsContainer .dateRangeWrapper span,
body[dir="rtl"] .analyticsContainer .description,
body[dir="rtl"] .history-container {
  direction: rtl;
}

body[dir="rtl"] .material-icons.chevron_right,
body[dir="rtl"] .material-icons.chevron_left,
body[dir="rtl"] .switchPlans .switch {
  transform: rotate(180deg);
}

body[dir="rtl"] .addMethodCard,
body[dir="rtl"] .addMethodCard .card {
  margin-left: auto;
}

body[dir="rtl"] .addMethodCard {
  padding-right: 0;
}

body[dir="rtl"] .dropdown-menu-right {
  left: 0px !important;
  right: auto !important;
}

body[dir="rtl"] .inputCounterContainer .counter {
  right: auto !important;
  left: -4px !important;
}

body[dir="rtl"] .modal-attrEdit .inputCounterContainer .counter {
  right: auto !important;
  left: 5px !important;
}

body[dir="rtl"] .text-center {
  text-align: center !important;
}

body[dir="rtl"] .listItemDetails textarea {
  text-align: right !important;
}

body[dir="rtl"] .counter {
  right: auto !important;
  left: 5px !important;
}

body[dir="rtl"] .msgContent span.requiredMessage {
  right: 17px !important;
  left: auto !important;
}

body[dir="rtl"] button#createFlow {
  right: auto !important;
  left: 3px !important;
}

body[dir="rtl"] .comparer-container select,
body[dir="rtl"] .usersCondition select,
body[dir="rtl"] select.attrInput {
  background-position-x: 10px;
}

body[dir="rtl"] .usersCondition select {
  padding: 0.5rem 2.2rem !important;
  font-size: 1rem;
}

body[dir="rtl"] input.inputWithCreateFlow {
  padding-right: 5px !important;
  padding-left: 90px !important;
}

body[dir="rtl"] div.sideBtn {
  position: absolute;
  right: auto !important;
  left: -12px !important;
}

body[dir="rtl"] .deleteBtn {
  right: auto !important;
  left: 0;
  border: 0;
  border-right: solid 1px #fad6f6;
  border-bottom: solid 1px #fad6f6;
  border-radius: 0px;
  border-bottom-right-radius: 3px;
}

body[dir="rtl"] .button-delete {
  right: 0;
  left: auto;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  border-right: 0 !important;
  border-left: auto !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #fad6f6;
}

body[dir="rtl"] .gallery-nav,
body[dir="rtl"] .scenarios-container .flows-answers {
  left: auto !important;
  right: 0 !important;
}

body[dir="rtl"] .url-options {
  font-size: 0.85rem;
  line-height: 1.3rem;
  font-weight: 580;
}

body[dir="rtl"] .button-add-card {
  right: auto !important;
  left: -1rem !important;
}

body[dir="rtl"] .choosenFlow span.choosenFlowName,
body[dir="rtl"] .wizardNav .nav-actions button.next {
  margin-right: auto !important;
}

body[dir="rtl"] .validationMessage {
  left: -105%;
  border-radius: 5px 0 0 5px;
}

body[dir="rtl"] .addQuickReplyButton,
body[dir="rtl"] .ButtonContainer {
  float: right;
}

body[dir="rtl"] .subBtn,
body[dir="rtl"] form.checkout .submitButton {
  float: left !important;
}

body[dir="rtl"] tree-node-expander {
  left: auto;
  right: 5px;
  transform: rotate(180deg);
}

body[dir="rtl"] button.itemBtn,
body[dir="rtl"] .buttonTargetTitle span,
body[dir="rtl"] .addQuickReplyButton {
  margin-left: 0;
  margin-right: 5px;
}

body[dir="rtl"] .buttonTargetTitle span {
  line-height: 17px;
}

body[dir="rtl"] section.paymentMethod,
body[dir="rtl"] .formContainer .BillingFormCol:last-of-type {
  margin-right: 0 !important;
  margin-left: 15px;
}

body[dir="rtl"] .payment-container {
  padding-left: 0 !important;
}

body[dir="rtl"] .container-card {
  padding: 0;
}

body[dir="rtl"] .switchPlan {
  direction: ltr;
}

body[dir="rtl"] .switchPlan label.option-conainer {
  direction: rtl;
}

body[dir="rtl"] .payment {
  direction: rtl;
}

body[dir="rtl"] .planInformation.proInfo {
  justify-content: flex-end !important;
}

body[dir="rtl"] .card .footer {
  direction: rtl;
  text-align: left;
}

body[dir="rtl"] .card .footer .btn {
  margin-right: 1rem;
}

body[dir="rtl"] .coupon-apply {
  direction: ltr;
}

body[dir="rtl"] .plan p {
  direction: rtl;
}

body[dir="rtl"] .coupon-container input {
  border-radius: 0 4px 4px 0 !important;
}

body[dir="rtl"] .coupon-container button {
  border-radius: 4px 0 0 4px !important;
}

body[dir="rtl"] .model-header {
  direction: rtl;
}

body[dir="rtl"] .model-body {
  direction: rtl;
}

body[dir="rtl"] .option-content {
  direction: ltr;
}

body[dir="rtl"] .model-body .features p {
  direction: rtl;
}

body[dir="rtl"] .price {
  direction: ltr;
}

body[dir="rtl"] .planAndPayment section:first-of-type,
body[dir="rtl"] .formContainer .BillingFormCol:first-of-type,
body[dir="rtl"] .radio input[type="radio"] + .radio-label:before {
  margin-right: 0 !important;
  margin-left: 15px;
}

body[dir="rtl"] .switchPlans p span {
  white-space: nowrap;
}

body[dir="rtl"] .mr-2 {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}

body[dir="rtl"] .ml-2 {
  margin-left: 0 !important;
  margin-right: 0.5rem !important;
}

body[dir="rtl"] .broadcastAlert {
  padding-right: 50px;
  padding-left: 15px;
}

body[dir="rtl"] .step-1 .broadcastAlert {
  left: auto;
  right: calc(100% + 10px);
}

body[dir="rtl"] .broadcastAlert p i {
  left: calc(100% + 15px);
  right: auto !important;
}

body[dir="rtl"] app-broadcast-creation .step-1 .switch {
  width: 90px;
}

body[dir="rtl"] .wizardNav .nav-actions button.next {
  margin-left: 20px !important;
  border-left: 0 !important;
  border-right: 1px solid #ddd !important;
}
body[dir="rtl"] .wizardNav .nav-actions button.next span {
  direction: rtl !important;
}

body[dir="rtl"] .wizardNav .nav-actions button.next .buttonText {
  margin-left: 20px !important;
  margin-right: 5px !important;
}

body[dir="rtl"] .wizardNav .nav-actions button.prev {
  border-left: 1px solid #ddd !important;
  border-right: 0 !important;
}
body[dir="rtl"] .wizardNav .nav-actions button.prev span {
  direction: rtl !important;
}

body[dir="rtl"] .wizardNav .nav-actions button.prev .buttonText {
  margin-right: 20px !important;
}

body[dir="rtl"] .step-2 .h1 {
  white-space: nowrap;
}

body[dir="rtl"] .broadcastCreateTime .time .mat-form-field,
body[dir="rtl"] .sla_time .mat-form-field,
body[dir="rtl"] .userAttrType .time .mat-form-field {
  margin-right: 0 !important;
  margin-left: 5px !important;
}

body[dir="rtl"] .broadcastCreateTime .repeat mat-form-field,
body[dir="rtl"] .userAttrType .repeat mat-form-field {
  padding-right: 0 !important;
  padding-left: 1rem !important;
}

body[dir="rtl"] label.second-placeholder {
  left: 0.6rem;
  right: auto;
}

body[dir="rtl"] .scenario-input-container input {
  text-align: center !important;
}

body[dir="rtl"] .scenario-container {
  direction: rtl;
}

body[dir="rtl"] .QnA-input-container,
body[dir="rtl"] .addQnA-input {
  float: right;
}

body[dir="rtl"] .question-input {
  padding-right: 1rem !important;
  padding-left: calc(1rem + 20px) !important;
}

body[dir="rtl"] .scenarios-container .float-left {
  float: right !important;
}

body[dir="rtl"] app-set-attribute .container .form-row {
  direction: rtl;
}

body[dir="rtl"] .container .form-row .inputform input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.form-control {
  unicode-bidi: plaintext;
  transition: none;
}

body[dir="rtl"] .container .form-row .userAttribute-wrapper {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #a9cef8;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

body[dir="rtl"] .container .form-row .userAttrNotTimeType {
  border-left: 0;
}

body[dir="rtl"] .attrList input {
  text-align: center !important;
}

body[dir="rtl"] .attrListWrapper button.addAttr {
  text-align: right !important;
}

body[dir="rtl"] .inputContent span.requiredMessage {
  left: auto;
  right: 0.4rem;
}

body[dir="rtl"] .message-container .jsonMethod select {
  font-size: 0.8rem !important;
  font-weight: bold;
  height: 2rem;
  padding: 0.2rem 0rem;
}

body[dir="rtl"] .jsonMethod {
  grid-template-columns: 90px calc(100% - 100px);
}

body[dir="rtl"] .compareWithContainer {
  direction: ltr;
}

body[dir="rtl"] .userAttribute-container {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

body[dir="rtl"] .conditionData.noneCondition {
  border-left: 1px solid #a9cef8;
  border-right: 0;
}

body[dir="rtl"] .app-dropdown-list {
  text-align: right;
}

body[dir="rtl"] .ifcondition select {
  height: 2rem;
}

body[dir="rtl"] .flowFilterResult button {
  text-align: right !important;
}

body[dir="rtl"] .addCompareWithBtn {
  direction: rtl;
}

body[dir="rtl"] .compareWithString {
  direction: rtl;
}

body[dir="rtl"] .conditionData select {
  border-left: none !important;
}

body[dir="rtl"] .conditionData input {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

body[dir="rtl"] .choosenFlow button.deleteFlow {
  left: 2px !important;
  right: auto;
}

body[dir="rtl"] .preview {
  border-radius: 20px 20px 20px 0px;
  text-align: left !important;
  float: left !important;
}

body[dir="rtl"] .scenario-tag {
  float: right !important;
}

body[dir="rtl"] .filterScenario {
  direction: rtl;
  margin: 0.5rem;
}

body[dir="rtl"] .delete-scenario {
  right: auto;
  left: 20px;
}

body[dir="rtl"] .msg {
  direction: rtl;
  text-align: right;
}

body[dir="rtl"] .messageTypeMenu.broadcastMessages .row-2 {
  justify-content: flex-end !important;
}

body[dir="rtl"] .attrList {
  right: auto;
  left: 0;
}

body[dir="rtl"] .paging-input-container button svg {
  transform: rotate(180deg);
}

body[dir="rtl"] button.backBtn svg {
  transform: rotate(180deg);
}

body[dir="rtl"] .menuContainer svg {
  transform: rotatey(180deg);
}

body[dir="rtl"] .paymentMethod svg {
  transform: rotatey(180deg);
}

body[dir="rtl"] .helpIcon svg {
  transform: rotatey(180deg);
}

body[dir="rtl"] .paymentMethod h2 {
  direction: rtl;
}

body[dir="rtl"] .gallery-shape-options {
  direction: ltr;
}

body[dir="rtl"] .option-conainer {
  margin-right: 0.5rem !important;
}

body[dir="rtl"] .attrListWrapper button.backBtn {
  direction: ltr;
}

body[dir="rtl"] .inputContainer label {
  left: 0.6rem;
  right: auto !important;
}

body[dir="rtl"] .inputContainer input {
  padding-left: calc(0.4rem + 81px) !important;
  padding-right: 0.4rem !important;
}

body[dir="rtl"] .mr-3 {
  margin-left: 0 !important;
  margin-right: 0.5rem !important;
}

body[dir="rtl"] .nav-item.messenger_link {
  margin-left: 0 !important;
  margin-right: 1rem !important;
}

body[dir="rtl"] .nav-space {
  margin-left: 0 !important;
  margin-right: 16px !important;
}

body[dir="rtl"] .ng-scrollbar {
  right: auto;
  left: 5px;
}

body[dir="rtl"] .flowsName-container .flow {
  float: right !important;
}

body[dir="rtl"] .userAttr {
  padding-left: 3px;
}

body[dir="rtl"] .headerData .inputContent input.inputText {
  font-size: 0.9rem;
}

body[dir="rtl"] .button-icon {
  direction: rtl;
}

body[dir="rtl"] .card-content:after {
  right: auto;
  width: 0;
  height: 0;
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
  border-left: 60px solid #fff;
  left: 100%;
  border-width: 15px;
}

body[dir="rtl"] .svg-icon {
  transform: rotatey(180deg);
}

body[dir="rtl"] .handOverBtn span {
  margin-left: 0.4rem;
}

body[dir="rtl"] .card-content {
  right: calc(100% - 20px);
}

body[dir="rtl"] .callback-options .callback {
  margin-right: 1rem !important;
}

body[dir="rtl"] .checkbox-container {
  display: flex;
  margin-top: 0.7rem;
}

body[dir="rtl"] .checkbox-container span {
  padding-left: 0.5rem;
}

body[dir="rtl"] .filterByText-form input {
  padding-inline-start: 2rem !important;
}

body[dir="rtl"] .scenarioTitle {
  direction: ltr;
  margin-bottom: 0.5rem;
}

i {
  font-style: inherit;
  color: inherit;
}

/* End rtl styles */

.long-text.bcTitle {
  display: inline-block;
  line-height: 1.2rem;
  max-width: 100%;
}

/* QnA Shared styles */

.QnA-input-container,
.addQnA-input {
  float: left;
}

.qna-input-wrapper::after {
  content: "";
  clear: both;
  display: block;
}

.addQnA-input {
  background: #fff;
  border: none;
  font-size: 2.5rem;
  font-weight: 600;
  color: #95aab4;
  height: 33px;
  line-height: 33px;
}

.addQnA-input:hover,
.addQnA-input:focus {
  color: #2956ef;
}

.processingOverlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

body[dir="rtl"] h4.mainTitle {
  direction: rtl;
}

.tooltip .arrow:before {
  border-top-color: #39487b !important;
  border-bottom-color: #39487b !important;
}

.tooltip.right .tooltip-arrow {
  border-style: none;
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #39487b;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-style: none;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #39487b;
}

.tooltip.left .tooltip-arrow {
  border-style: none;
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #39487b;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-style: none;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-left-color: #39487b;
}

.tooltip {
  opacity: 1 !important;
  z-index: 99999999999;
  /*To make the tooltip over the modal*/
}

.tooltip .tooltip-inner {
  background-color: #39487b;
}

body[dir="rtl"] .__tooltip-start .tooltip {
  margin-inline-start: -15px;
}

.__tooltip-start .tooltip-arrow.arrow {
  left: 12px !important;
}

body[dir="rtl"] .__tooltip-start .tooltip-arrow.arrow {
  left: auto !important;
  right: 12px !important;
}

.logsTooltip .tooltip-arrow.arrow {
  display: none;
}

.logsTooltip .tooltip {
  box-shadow: 0px 4px 8px #dadada;
  border-radius: 0.25rem;
  padding: 0 !important;
  margin-inline-end: 15px;
}

.logsTooltip .tooltip .tooltip-inner {
  color: #1b2733;
  background-color: #e7ecff;
  width: max-content;
}

body[dir="rtl"] .logsTooltip .history-container header {
  flex-direction: row;
}

body[dir="rtl"] .logsTooltip .history-container header button {
  margin-left: 0;
  margin-right: auto;
}

body[dir="rtl"] .jsonPopOver {
  direction: rtl;
}

.jsonPopOver {
  background-color: #39487b;
}

.jsonPopOver .popover-body {
  color: #fff;
  text-align: center;
}

.jsonPopOver .popover-body a {
  color: #fff;
  font-weight: 600;
}

.jsonPopOver .arrow:before,
.jsonPopOver .arrow:after {
  border-bottom-color: #39487b;
}

.jsonPopOver .arrow:before {
  top: 1px;
}

body[dir="rtl"] .ng-select {
  text-align: initial !important;
}

.ng-select .ng-select-container {
  border: solid 1px #fad6f6 !important;
  border-radius: 0.5rem !important;
  box-shadow: none !important;
}

body[dir="rtl"] .ng-select .ng-arrow-wrapper {
  padding-left: 5px;
  width: 25px;
}

body[dir="rtl"] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  right: 0;
  padding-right: 10px;
  padding-left: 50px;
  top: 5px;
}

body[dir="rtl"] .ng-select .ng-select-container .ng-value-container {
  align-items: center;
  padding-right: 10px;
}

body[dir="rtl"] .form-container {
  direction: ltr;
}

body[dir="rtl"] .createButton {
  left: 5px;
}

.leaveBotModal.modal-body {
  text-align: start;
  font-size: 1.2rem;
  color: #000;
  font-weight: 600;
  padding: 0;
}

.leaveBotModal .modalActions button.confirm {
  background: #f9405f;
  color: #fff;
  margin-inline-start: 1rem;
  border: none;
}

/* date-time-picker edits */

.owl-dt-timer-hour12 .owl-dt-timer-hour12-box {
  width: 45px;
  height: 35px;
  background: #3366ff;
  color: #fff;
  border-radius: 4px;
}

.owl-dt-timer-content .owl-dt-timer-input {
  width: 45px;
  height: 35px;
  background: #f7f9fc;
  border: 1px solid #d5dce6;
  border-radius: 4px;
}

.owl-dt-timer-divider:after,
.owl-dt-timer-divider:before {
  background-color: transparent;
  width: auto;
  height: auto;
}

.owl-dt-timer-divider:after {
  content: ":";
  position: absolute;
  top: calc(50% - 16px);
  font-size: 20px;
}

.owl-dt-control-arrow-button svg {
  fill: #3366ff;
}

/* To fill the container and nothing else */

.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget span iframe[style] {
  width: 100% !important;
}

.fb_iframe_widget span {
  width: 160px !important;
}

body[dir="rtl"] .btnPopOver {
  direction: rtl;
}

.btnPopOver {
  background-color: #39487b;
}

.btnPopOver .popover-body {
  color: #fff;
  text-align: center;
}

.btnPopOver .popover-body a {
  color: #fff;
  font-weight: 600;
}

.btnPopOver .arrow:before,
.btnPopOver .arrow:after {
  border-bottom-color: #39487b;
  border-top-color: #39487b;
}

.btnPopOver .arrow:before {
  top: 1px;
}

app-webchat .btnPopOver .arrow:before,
app-webchat .btnPopOver .arrow:after {
  border-bottom-color: transparent;
}

app-webchat .bs-popover-left .arrow::after,
app-webchat .bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #39487b;
}

app-webchat .bs-popover-right .arrow::after,
app-webchat .bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #39487b;
}

.business-pobover {
  max-width: 230px;
  background-color: #000a3f;
}

.business-pobover .popover-body {
  color: #d8d8d8;
  font-size: 14px;
  line-height: 24px;
}

.business-pobover .arrow:before,
.btnPopOver .arrow:after {
  border-top-color: #000a3f;
}

body[dir="rtl"] .business-pobover {
  top: -99px !important;
  left: 168px !important;
}

.large-modal {
  max-width: 700px;
}

.medium-modal {
  max-width: 600px;
}

.xLarge-modal {
  max-width: 1044px;
}

.xLarge-modal .modal-content {
  max-height: 95vh;
}

.xLarge-modal .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
}

.Large-modal {
  max-width: 830px;
}

.small-modal {
  max-width: 465px;
}

.small-modal .modal-content {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  max-width: 450px;
}

body[dir="rtl"] .clone-folder-footer {
  justify-content: flex-start !important;
}

.bots-select .ng-select-container {
  border: 1px solid #ebecf0 !important;
}

body[dir="rtl"] .modalContent .text-start {
  text-align: right;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #2956ef;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
  background-color: #d0d3d5;
}

.working-days-check .mat-checkbox-layout .mat-checkbox-label {
  font-weight: normal;
  font-size: 19px;
  line-height: 23px;
  padding: 0 10px;
}

/* .mat-checkbox-ripple .mat-ripple-element {
  background: #2956ef !important;
  opacity: 0.5;
} */

.format-ng-select.ng-select-single .ng-select-container,
.time-ng-select.ng-select-single .ng-select-container {
  height: 48px;
}

body[dir="rtl"] .modalContent .text-start {
  text-align: right;
}

.addons-modal {
  max-width: 635px;
}

.iconHeight {
  min-height: 10rem;
}

/* remove arrow from input type number */

/* Chrome, Safari, Edge, Opera */

.seatsModal input::-webkit-outer-spin-button,
.seatsModal input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

.seatsModal input[type="number"] {
  -moz-appearance: textfield;
}

body[dir="rtl"] .modal-container .submit-admin {
  direction: rtl;
}

body[dir="rtl"] .enable2faModal .enable2fa {
  direction: ltr;
}

body[dir="rtl"] .modal2fa .auth-apps,
body[dir="rtl"] .modal2fa .codes-d-flex,
body[dir="rtl"] .modal2faHeader,
body[dir="rtl"] .backupBody h4,
body[dir="rtl"] .updateSeatsModal .modal-header,
body[dir="rtl"] .removeSeatsModal .modal-header,
body[dir="rtl"] .seatsModal .modal-header {
  direction: rtl;
}

body[dir="rtl"] .enable2faModal .enable2fa a.btn.cancel {
  padding: 8px 10px;
}

.botsDataModal .loading-data {
  width: 150px;
  height: 150px;
  border: 4px solid #2956ef;
  padding: 30px 10px;
  border-radius: 50%;
  margin: 15px auto;
  margin-top: 30px;
}

.botsDataModal .loading-data h5 {
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #1b2733;
  font-weight: bold;
  padding: 5px 0;
}
.botsDataModal .loading-data p {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #555b61;
}

body[dir="rtl"] .modalHeader {
  direction: rtl;
}

app-alerts {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

bs-daterangepicker-container {
  margin-top: 120px;
}

.intercom-lightweight-app {
  position: fixed !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
}

.intercom-lightweight-app-launcher {
  position: fixed !important;
  right: 20px !important;
  left: auto !important;
  bottom: 20px !important;
}

body[dir="rtl"] .intercom-lightweight-app-launcher {
  position: fixed !important;
  right: auto !important;
  left: 20px !important;
  bottom: 20px !important;
}

/*                  material icons                  */

.material-icons.fs-2 {
  font-size: 1rem;
}

.material-icons.fs-3 {
  font-size: 1.2rem;
}

.material-icons.add-icon {
  vertical-align: bottom;
}

.material-icons.middle,
.material-icons-outlined.middle {
  vertical-align: middle;
}

/*          for market place apps modal             */

.marketplace-modal {
  max-width: 700px;
}

.marketplace-modal .modal-content {
  padding: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
}

.large-modal.second-modal .modal-content {
  margin: 0 10px;
}

.app-modal-header {
  background-color: rgba(24, 135, 239, 0.16);
}

.btn-upgrade {
  background-color: #ff5100;
  color: #fff;
}

.btn-link:focus {
  box-shadow: none !important;
  border: none !important;
}

.btn-link:hover {
  text-decoration: none;
}

.rounded-lg {
  border-radius: 0.6rem !important;
}

.overflow-ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.block-screen {
  position: absolute;
  inset: 0;
  pointer-events: none;
  cursor: not-allowed;
  background-color: rgba(255, 255, 255, 0.1);
}

.block-screen button.reload-window {
  cursor: pointer;
  pointer-events: all;
}

/* New styles adjusted for HULUL */

.bg-hulul-primary,
.btn-hulul-primary,
.btn-hulul-primary:hover:not(:disabled):not(.disabled),
.btn-hulul-primary:focus:not(:disabled):not(.disabled) {
  background-color: #030315;
  color: #bbb;
}

.bg-hulul-light {
  background-color: #f5f5f6;
}

.btn-hulul-primary {
  background-color: #030315;
  color: #fff;
}

.bg-hulul-secondary {
  background-color: #01e68d;
}
.btn-domain-secondary,
.btn-domain-secondary:hover:not(:disabled):not(.disabled),
.btn-domain-secondary:focus:not(:disabled):not(.disabled) {
  color: #fff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.section-card {
  border: 1px solid lightblue;
  border-radius: 0.6rem;
}
